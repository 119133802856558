import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Menu from "./Menu";
import Button from "./Button";

/**
 * Header component for forest-themed web app
 *
 * @returns {React.Element} A styled header component with navigation menu and authentication controls
 */
const Header = () => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const publicMenuItems = [
    { label: "Home", href: "/" },
    { label: "Opportunities", href: "/due-diligences" },
  ];

  const privateMenuItems = [
    { label: "Forests", href: "/forests" },
    { label: "Custom Requests", href: "/debug" },
  ];

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const menuItems = isAuthenticated
    ? [...publicMenuItems, ...privateMenuItems]
    : publicMenuItems;

  return (
    <header className="bg-forest-800 text-white shadow-md fixed top-0 left-0 right-0 z-50">
      <div className="container mx-auto px-4 bg-forest-800">
        {" "}
        {/* Using RGB value */}
        <div className="flex justify-between items-center py-4">
          {/* Logo */}
          <Link
            to="/"
            className="flex items-center text-2xl font-nature font-bold text-forest-100 hover:text-forest-300 transition duration-300"
          >
            <svg
              className="w-8 h-8 mr-2"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 22V12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12V22"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 7V4M12 4H9M12 4H15"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 22V19"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            OA Forest Hub
          </Link>

          {/* Desktop navigation */}
          <nav className="hidden md:flex space-x-4 items-center">
            {menuItems.map((item, index) => (
              <Link
                key={index}
                to={item.href}
                className="font-sans text-forest-100 hover:text-forest-300 transition duration-300"
              >
                {item.label}
              </Link>
            ))}
            {isAuthenticated ? (
              <Button variant="secondary" size="small" onClick={handleLogout}>
                Logout
              </Button>
            ) : (
              <Button
                variant="primary"
                size="small"
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            )}
          </nav>

          {/* Mobile menu */}
          <div className="md:hidden">
            <Menu
              title="Menu"
              items={[
                ...menuItems,
                isAuthenticated
                  ? { label: "Logout", onClick: handleLogout }
                  : { label: "Login", href: "/login" },
              ]}
              className="w-40"
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
