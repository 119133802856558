import React from 'react';
import FoldableSection from '../DueDiligenceComponents/FoldableSection';
import ArcGISMap from './ArcGISMap';

const FoldableMapSection = ({ webmapId, extent }) => {
  return (
    <FoldableSection title="Property Map">
      <div className="w-full h-[500px]">
        <ArcGISMap
          webmapId={webmapId}
          extent={extent}
          height="100%"
        />
      </div>
    </FoldableSection>
  );
};

export default FoldableMapSection;