import React from 'react';
import FoldableSection from '../FoldableSection';
import CommentBlock from '../CommentBlock';
import ImplicationsBlock from '../ImplicationsBlock';

const NextSteps = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <FoldableSection title="Next Steps" defaultOpen={true}>
          <CommentBlock content={data.next_steps} />
          <ImplicationsBlock implications={data.recommendation} title="Current Recommendation" />
    </FoldableSection>
  );
};

export default NextSteps;