import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  const backgroundStyle = {
    backgroundImage: 'url("https://images.unsplash.com/photo-1426170042593-200f250dfdaf?q=80&w=2560&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <div className="min-h-screen flex items-center justify-center" style={backgroundStyle}>
      <div className="bg-white bg-opacity-80 p-8 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Welcome to OA Forest Hub</h1>
        <p className="text-xl text-gray-600 mb-8">Invest in sustainable forests and make a positive impact on the environment.</p>
        <Link to="/forests" className="bg-green-600 text-white px-6 py-3 rounded-lg hover:bg-green-700 transition duration-300 inline-block">
          Explore Forests
        </Link>
      </div>
    </div>
  );
};

export default Home;