import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

/**
 * Layout component
 * Provides a consistent layout structure for all pages
 */
const Layout = () => {
  return (
    <div className="flex flex-col min-h-screen bg-forest-50">
      <Header />
      <main className="flex-grow pt-16"> {/* Add padding-top here */}
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;