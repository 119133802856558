import React, {useState } from 'react';
import { FaInfoCircle, FaCalendarAlt, FaTree, FaDollarSign, FaRulerCombined, FaChevronDown } from 'react-icons/fa';
import AttributeItem from './AttributeItem';

const BouncingArrow = () => (
  <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-white animate-bounce">
    <FaChevronDown size={24} />
  </div>
);

const DueDiligenceBanner = ({ data }) => {
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const hectares = data.propertySizeGIS || NaN;
  const acres = (hectares * 2.47105).toFixed(2);
  const askingPricePerHa = data.askingPricePerHa || NaN;
  const pricePerAcre = (askingPricePerHa / 2.47105).toFixed(2);

  const formatPrice = (price) => {
    return `$${parseInt(price).toLocaleString()}`;
  };

  return (
    <div className="relative w-full h-[80vh] mb-8">
      <div 
        className="absolute inset-0 bg-cover bg-center z-0"
        style={{
          backgroundImage: `url(${data.imageUrl})`,
          filter: "brightness(0.7)"
        }}
      ></div>
      <div className="relative z-10 bg-black bg-opacity-30 text-white p-6 h-full flex flex-col justify-between">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
          <div className="py-8">
            <h1 className="text-5xl font-bold mb-2 text-white px-4">{data.name || 'Property Name'}</h1>
          </div>
        </div>
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full mb-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <AttributeItem
              label="Total Area"
              value={`${hectares} ha / ${acres} ac`}
              icon={FaTree}
              color="text-green-600"
              borderColor="border-white"
            />
            <AttributeItem
              label="Estimated Purchase Price"
              value={formatPrice(data.purchasePrice)}
              icon={FaDollarSign}
              color="text-green-600"
              borderColor="border-white"
            />
            <AttributeItem
              label="Price per Area"
              value={`${formatPrice(pricePerAcre)}/ac | ${formatPrice(askingPricePerHa)}/ha`}
              icon={FaRulerCombined}
              color="text-green-600"
              borderColor="border-white"
            />
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
          <div className="flex justify-between items-end space-x-4">
            <div className="flex items-center flex-shrink-0">
              <FaCalendarAlt className="mr-2 text-xl" />
              <p className="text-lg whitespace-nowrap">{data.startDate || 'Date not available'}</p>
            </div>
            <div className="flex items-center relative">
              <FaInfoCircle 
                className="text-xl cursor-pointer" 
                onMouseEnter={() => setShowDisclaimer(true)}
                onMouseLeave={() => setShowDisclaimer(false)}
              />
              {showDisclaimer && (
                <div className="absolute bottom-full right-0 mb-2 p-4 bg-white text-black rounded-lg shadow-lg w-80 max-w-sm">
                  <p className="text-sm">{data.disclaimer || 'No disclaimer available'}</p>
                  <div className="absolute bottom-0 right-4 transform translate-y-1/2 rotate-45 w-3 h-3 bg-white"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      
      <BouncingArrow />
    </div>
  );
};

export default DueDiligenceBanner;