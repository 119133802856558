import React, { useState } from 'react';
import { queryNotionDatabase, retrieveNotionPage } from '../../services/notionService';
import '../../styles/NotionDebug.css';

/**
 * NotionDebug component for querying Notion databases, retrieving pages, and displaying raw responses.
 * This component provides a clean, structured interface for debugging Notion queries and page retrievals.
 */
const NotionDebug = () => {
  const [databaseId, setDatabaseId] = useState('');
  const [filter, setFilter] = useState('');
  const [pageId, setPageId] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleDatabaseQuery = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setResponse('');

    try {
      let parsedFilter = filter ? JSON.parse(filter) : {};
      const result = await queryNotionDatabase(databaseId, parsedFilter);
      setResponse(JSON.stringify(result, null, 2));
    } catch (err) {
      setError('Error querying Notion database: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageRetrieval = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setResponse('');

    try {
      const result = await retrieveNotionPage(pageId);
      setResponse(JSON.stringify(result, null, 2));
    } catch (err) {
      setError('Error retrieving Notion page: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="notion-debug">
      <h1>Custom Data Requests</h1>
      
      <div className="debug-section">
        <h2>Query Database</h2>
        <form onSubmit={handleDatabaseQuery}>
          <div className="form-group">
            <label htmlFor="databaseId">Database ID</label>
            <input
              type="text"
              id="databaseId"
              value={databaseId}
              onChange={(e) => setDatabaseId(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="filter">Filter (optional, JSON format)</label>
            <textarea
              id="filter"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              placeholder='e.g. {"property": "Name", "text": {"contains": "Forest"}}'
            />
          </div>
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Querying...' : 'Query Database'}
          </button>
        </form>
      </div>

      <div className="debug-section">
        <h2>Retrieve Page</h2>
        <form onSubmit={handlePageRetrieval}>
          <div className="form-group">
            <label htmlFor="pageId">Page ID</label>
            <input
              type="text"
              id="pageId"
              value={pageId}
              onChange={(e) => setPageId(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Retrieving...' : 'Retrieve Page'}
          </button>
        </form>
      </div>

      {error && <div className="error">{error}</div>}
      {response && (
        <div className="response">
          <h2>Response</h2>
          <pre>{response}</pre>
        </div>
      )}
    </div>
  );
};

export default NotionDebug;