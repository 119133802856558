import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';

const ImplicationsBlock = ({ implications = 'Pending', title = "Implications" }) => {
  return (
    <div className="mb-4 bg-yellow-50 border-l-4 border-yellow-500 p-4 rounded">
      <div className="flex items-center mb-2">
        <FaExclamationTriangle className="text-lg text-yellow-500 mr-2" />
        <h4 className="text-md font-semibold">{title}</h4>
      </div>
      <p className="text-gray-700">{implications}</p>
    </div>
  );
};

export default ImplicationsBlock;