import React, { useEffect, useState } from 'react';

const ArcGISMap = ({ webmapId, extent, height = '100vh' }) => {
  const [mapHeight, setMapHeight] = useState(height);

  useEffect(() => {
    const updateMapHeight = () => {
      if (height === '100vh') {
        const windowHeight = window.innerHeight;
        const header = document.querySelector('header');
        const footer = document.querySelector('footer');
        const headerHeight = header ? header.offsetHeight : 0;
        const footerHeight = footer ? footer.offsetHeight : 0;
        const availableHeight = windowHeight - headerHeight - footerHeight;
        setMapHeight(`${availableHeight}px`);
      } else {
        setMapHeight(height);
      }
    };

    updateMapHeight();
    window.addEventListener('resize', updateMapHeight);

    return () => window.removeEventListener('resize', updateMapHeight);
  }, [height]);

  return (
    <div style={{ height: mapHeight, display: 'flex', flexDirection: 'column' }}>
      <div className="embed-container" style={{ flex: 1 }}>
        <style>{`
          .embed-container {
            position: relative;
            height: 100%;
            width: 100%;
          }
          .embed-container iframe,
          .embed-container object,
          .embed-container iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          small {
            position: absolute;
            z-index: 40;
            bottom: 0;
            margin-bottom: -15px;
          }
        `}</style>
        <iframe
          width="100%"
          height="100%"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          title="ArcGIS Web Map"
          src={`//arcgis.com/apps/Embed/index.html?webmap=${webmapId}&extent=${extent}&zoom=true&previewImage=false&scale=true&disable_scroll=true&theme=light`}
        ></iframe>
      </div>
    </div>
  );
};

export default ArcGISMap;