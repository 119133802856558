import React from 'react';
import {FaDollarSign, FaRuler } from 'react-icons/fa';
import FoldableSection from '../FoldableSection';
import AttributeItem from '../AttributeItem';
import CommentBlock from '../CommentBlock';

const RoadNetwork = ({ data }) => {
  // Helper function to format numbers
  const formatNumber = (num) => {
    return num != null && !isNaN(num) ? num.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 'N/A';
  };

  const attributes = [
    {
      label: "Length Log Truck Roads (m)",
      value: formatNumber(data.lengthLogTruckRoads),
      icon: FaRuler,
      unit: "Metre"
    },
    {
      label: "Length Log Truck Roads Total (CAD)",
      value: formatNumber(data.totalLogTruckRoads),
      icon: FaDollarSign,
      unit: "CAD"
    },
    {
      label: "Length Pickup Roads (m)",
      value: formatNumber(data.lengthPickupRoads),
      icon: FaRuler,
      unit: "Metre"
    },
    {
      label: "Length Pickup Roads Total (CAD)",
      value: formatNumber(data.totalPickupRoads),
      icon: FaDollarSign,
      unit: "CAD"
    },
    {
      label: "Total Road Value (CAD)",
      value: formatNumber(data.roadNetworkValue),
      icon: FaDollarSign,
      unit: "CAD",
      color: 'text-blue-600',
      borderColor: 'border-blue-600'
    }
  ];

  return (
    <FoldableSection title="Road Network">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
        {attributes.map((attribute, index) => (
          <AttributeItem key={index} {...attribute} />
        ))}
      </div>
      <CommentBlock content={data.commentsRoadNetwork} />
    </FoldableSection>
  );
};

export default RoadNetwork;