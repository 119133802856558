import React from 'react';

const AttributeItem = ({ label, value, icon: Icon, unit, color, borderColor }) => (
  <div className={`bg-gray-100 rounded-lg p-4 flex items-center border-2 ${borderColor}`}>
    <div className="mr-4">
      {Icon && <Icon className={`text-2xl ${color || 'text-green-600'}`} />}
    </div>
    <div>
      <p className="text-sm font-medium text-gray-500">{label}</p>
      <p className={`text-lg font-semibold ${color || 'text-gray-800'}`}>
        {value} <span className="text-sm text-gray-500">{unit}</span>
      </p>
    </div>
  </div>
);

export default AttributeItem;