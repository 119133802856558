import React from 'react';

/**
 * Footer component for forest-themed web app
 * 
 * @returns {React.Element} A styled footer component with links and copyright information
 */
const Footer = () => {
  return (
    <footer className="bg-forest-800 text-forest-100">
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
          <div className="mb-4 md:mb-0">
            <h3 className="text-xl font-nature font-bold mb-2">OA Forest Hub</h3>
            <p className="text-sm">&copy; 2024 Ovis Analytics. All rights reserved.</p>
          </div>
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6">
            <a href="/privacy-policy" className="hover:text-forest-300 transition duration-300">Privacy Policy</a>
            <a href="/terms-of-service" className="hover:text-forest-300 transition duration-300">Terms of Service</a>
            <a href="/contact" className="hover:text-forest-300 transition duration-300">Contact Us</a>
          </div>
        </div>
        <div className="mt-8 text-center md:text-left">
          <p className="text-sm text-forest-300">
            Creating forest opportunities, one click at a time.
          </p>
          <div className="mt-2">
            <svg 
              className="inline-block w-6 h-6 text-forest-400" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24" 
              xmlns="http://www.w3.org/2000/svg"
              aria-label="Leaf icon"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
            </svg>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;