import React from 'react';
import { FaTree, FaPercentage, FaDollarSign } from 'react-icons/fa';
import FoldableSection from '../FoldableSection';
import AttributeItem from '../AttributeItem';
import CommentBlock from '../CommentBlock';

const ForestAndStumpageValue = ({ data }) => {
  // Helper function to format numbers
  const formatNumber = (num) => {
    return num != null && !isNaN(num) ? num.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 'N/A';
  };

  // Helper function to format percentages
  const formatPercentage = (num) => {
    return num != null && !isNaN(num) ? `${(num * 100).toFixed(1)}%` : 'N/A';
  };

  return (
    <FoldableSection title="Forest & Stumpage Value">
      <h3 className="text-lg font-semibold mb-2">Species</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <AttributeItem
          label="%SW"
          value={formatPercentage(data.percentageSW)}
          icon={FaPercentage}
          unit=""
        />
        <AttributeItem
          label="%HW"
          value={formatPercentage(data.percentageHW)}
          icon={FaPercentage}
          unit=""
        />
      </div>

      <h3 className="text-lg font-semibold mb-2">Young Forest</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <AttributeItem
          label="Untreated (Ha)"
          value={formatNumber(data.untreatedHa)}
          icon={FaTree}
          unit="Hectare"
        />
        <AttributeItem
          label="Total Untreated (CAD)"
          value={formatNumber(data.totalUntreated)}
          icon={FaDollarSign}
          unit="CAD"
          color="text-blue-600"
        />
        <AttributeItem
          label="Treated (Ha)"
          value={formatNumber(data.treatedHa)}
          icon={FaTree}
          unit="Hectare"
        />
        <AttributeItem
          label="Planting (Ha)"
          value={formatNumber(data.plantingHa)}
          icon={FaTree}
          unit="Hectare"
        />
        <AttributeItem
          label="ECC (ha)"
          value={formatNumber(data.eccHa)}
          icon={FaTree}
          unit="Hectare"
        />
        <AttributeItem
          label="PCT (Ha)"
          value={formatNumber(data.pctHa)}
          icon={FaTree}
          unit="Hectare"
        />
        <AttributeItem
          label="Total Treated (CAD)"
          value={formatNumber(data.totalTreatedValue)}
          icon={FaDollarSign}
          unit="CAD"
          color="text-blue-600"
        />
        <AttributeItem
          label="Total (CAD)"
          value={formatNumber(data.totalForestValue)}
          icon={FaDollarSign}
          unit="CAD"
          color="text-blue-600"
        />
      </div>
      <CommentBlock content={data.commentsForest} />

      <h3 className="text-lg font-semibold mb-2">Merchantable Volume</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <AttributeItem
          label="SW (mton)"
          value={formatNumber(data.swMton)}
          icon={FaTree}
          unit="mton"
        />
        <AttributeItem
          label="HW (mton)"
          value={formatNumber(data.hwMton)}
          icon={FaTree}
          unit="mton"
        />
        <AttributeItem
          label="Total (mton)"
          value={formatNumber(data.totalVolume)}
          icon={FaTree}
          unit="mton"
          color="text-blue-600"
        />
        <AttributeItem
          label="mton/ha op. land"
          value={formatNumber(data.mtonHaOpLand)}
          icon={FaTree}
          unit="mton/ha op. land"
        />
        <AttributeItem
          label="Stumpage SW (CAD)"
          value={formatNumber(data.stumpageSW)}
          icon={FaDollarSign}
          unit="CAD"
        />
        <AttributeItem
          label="Stumpage HW (CAD)"
          value={formatNumber(data.stumpageHW)}
          icon={FaDollarSign}
          unit="CAD"
        />
        <AttributeItem
          label="Stumpage Value (CAD)"
          value={formatNumber(data.stumpageValue)}
          icon={FaDollarSign}
          unit="CAD"
          color="text-blue-600"
        />
      </div>
      <CommentBlock content={data.commentsMerchantableVolume} />
    </FoldableSection>
  );
};

export default ForestAndStumpageValue;