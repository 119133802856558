import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Layout from './components/BaseComponents/Layout';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './pages/Login';
import Home from './pages/Home';
import ForestGallery from './pages/ForestGallery';
import NotionDebug from './pages/Testing/NotionDebug';
import DueDiligenceView from './pages/DueDiligenceView';
import GisTesting from './pages/Testing/GisTesting';
import DueDiligenceGallery from './pages/DueDiligenceGallery';

/**
 * Main App component
 * @returns {JSX.Element} The main application structure
 */
function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/due-diligence/:id" element={<DueDiligenceView />} />
            <Route path="/due-diligences" element={<DueDiligenceGallery />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/forests" element={<ForestGallery />} />
              <Route path="/debug" element={<NotionDebug />} />
              <Route path="/gis-testing" element={<GisTesting />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;