import React from 'react';

const About = () => {
  return (
    <div className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-gray-900 mb-4">About Ovis Analytics Forest Hub</h2>
        <p className="text-xl text-gray-600">
          Ovis Analytics Forest Hub is a platform dedicated to connecting investors with sustainable forest projects around the world and giving them the tools to make informed decisions. Our mission is to promote conservation and responsible forest management while providing attractive investment opportunities.
        </p>
      </div>
    </div>
  );
};

export default About;