import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

/**
 * Login component for user authentication
 * @returns {JSX.Element} Login form
 */
const Login = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  /**
   * Handle form submission
   * @param {Event} e - Form submission event
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (code.length !== 4) {
      setError('Please enter your access code');
      return;
    }
    if (code === '1234') { // Replace with your desired code
      login();
      navigate('/');
    } else {
      setError('Invalid code');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-forest-50">
      <div className="px-8 py-6 mt-4 text-left bg-white shadow-lg rounded-lg">
        <h3 className="text-2xl font-bold text-center text-forest-800">Login to Your Account</h3>
        <form onSubmit={handleSubmit}>
          <div className="mt-4">
            <div>
              <label className="block text-forest-700" htmlFor="code">Code</label>
              <input
                type="password"
                placeholder="Enter 4-digit code"
                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-forest-500"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                maxLength={4}
              />
            </div>
            {error && <div className="text-red-500 mt-2">{error}</div>}
            <div className="flex items-baseline justify-between">
              <button className="px-6 py-2 mt-4 text-white bg-forest-600 rounded-lg hover:bg-forest-700 transition duration-300" type="submit">Login</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;