import React, { useRef } from 'react';

/**
 * ForestCard component
 * Displays a card with summary information about a forest
 * @param {Object} forest - The forest object containing all the forest data
 * @param {React.ReactNode} children - Child components, expected to be a Button
 */
const ForestCard = ({ forest, children }) => {
  const cardRef = useRef(null);

  // Helper function to format numbers
  const formatNumber = (num) => {
    return num ? num.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 'N/A';
  };

  const firstImageUrl = forest.imageUrls && forest.imageUrls.length > 0 ? forest.imageUrls[0] : null;

  const handleMouseMove = (e) => {
    const effectStrength = 20;
    const card = cardRef.current;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left - rect.width / 2;
    const y = e.clientY - rect.top - rect.height / 2;
    card.style.transform = `rotateX(${y / effectStrength}deg) rotateY(${x / effectStrength}deg)`;
  };

  const handleMouseLeave = () => {
    const card = cardRef.current;
    card.style.transform = 'rotateX(0deg) rotateY(0deg)';
  };

  const handleClick = () => {
    // Navigate to the forest details page
    window.location.href = `/forest/${forest.id}`;
  };

  return (
    <div
      ref={cardRef}
      className="bg-white rounded-md shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300 cursor-pointer"
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {firstImageUrl ? (
        <div className="h-48 overflow-hidden">
          <img 
            src={firstImageUrl} 
            alt={forest.name} 
            className="w-full h-full object-cover"
            onError={(e) => {
              console.error(`Error loading image for forest: ${forest.id}`);
              e.target.style.display = 'none';
            }}
          />
        </div>
      ) : (
        <div className="h-48 bg-gray-200 flex items-center justify-center">
          <p className="text-gray-600">No image available</p>
        </div>
      )}
      <div className="p-4">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">{forest.name || 'Unnamed Forest'}</h2>
        
        <div className="grid grid-cols-2 gap-4 mb-4">
          <InfoItem label="Area (Ha)" value={formatNumber(forest.area)} />
          <InfoItem label="Location" value={forest.location || 'Unknown'} />
          <InfoItem label="Tree Species" value={forest.treeSpecies?.join(', ') || 'N/A'} />
          <InfoItem label="Related Docs" value={forest.relatedDocumentation?.length.toString() || '0'} />
          <InfoItem label="Carbon Credits" value={formatNumber(forest.carbonCredits)} />
          <InfoItem label="Conservation Status" value={forest.conservationStatus || 'Unknown'} />
        </div>

        <div onClick={(e) => e.stopPropagation()} className="mt-4">
          {children}
        </div>
      </div>
    </div>
  );
};

/**
 * InfoItem component
 * Displays a label and its corresponding value
 * @param {string} label - The label for the information
 * @param {string} value - The value of the information
 */
const InfoItem = ({ label, value }) => (
  <div>
    <p className="text-sm font-medium text-gray-600">{label}</p>
    <p className="text-base font-semibold text-gray-800">{value}</p>
  </div>
);

export default ForestCard;