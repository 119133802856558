import React from 'react';
import ArcGISMap from '../../components/MapComponents/ArcGISMap';

const GisTesting = () => {
  const webmapId = '4ed18d500401447b89be213986cfecc1';
  const extent = '-71.3379,42.0084,-55.1111,48.8863';

  return (
    <ArcGISMap
      webmapId={webmapId}
      extent={extent}
      height="100vh"
    />
  );
};

export default GisTesting;