import React from 'react';

const ContactInfo = () => {
  return (
    <div className="bg-gray-100 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-gray-900 mb-4">Contact Us</h2>
        <p className="text-xl text-gray-600 mb-8">Have questions or want to learn more? Get in touch with our team.</p>
        <div className="bg-white shadow-md rounded-lg p-6">
          <p className="mb-4"><strong>Email:</strong> chris@ovisanalytics.com</p>
          <p className="mb-4"><strong>Email:</strong> jan@ovisanalytics.com</p>
          <p><strong>Address:</strong> St. Anna Platz 2, 80538 Munich, Germany</p>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;