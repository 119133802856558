import React, { useState, useEffect } from 'react';
import { fetchAndParseForestData, fetchAndParseForestImages } from '../services/notionService';
import ForestCard from '../components/PortfolioComponents/ForestCard';
import Button from '../components/BaseComponents/Button';
import Menu from '../components/BaseComponents/Menu';

const NOTION_DATABASE_ID = 'b268a7f84bee4b78bd97d07b24e370c5';

/**
 * ForestGallery component
 * Displays a gallery of ForestCard components with data fetched from Notion
 */
const ForestGallery = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [forests, setForests] = useState([]);
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    const fetchForests = async () => {
      try {
        console.log('Fetching forests from Notion API...');
        console.log('Database ID:', NOTION_DATABASE_ID);
        const forestsData = await fetchAndParseForestData(NOTION_DATABASE_ID);
        console.log(`Successfully fetched ${forestsData.length} forests`);

        setForests(forestsData);
        setLoading(false);

        forestsData.forEach(async (forest, index) => {
          console.log(`Processing forest: ${forest.name} (ID: ${forest.id})`);
          if (forest.images > 0) {
            console.log(`Fetching related images for forest ${forest.name}`);
            try {
              const imageUrls = await fetchAndParseForestImages(forest.images);
              console.log(`Retrieved ${imageUrls.length} image URLs for forest ${forest.name}`);
              
              setForests(prevForests => {
                const updatedForests = [...prevForests];
                updatedForests[index] = { ...updatedForests[index], imageUrls };
                return updatedForests;
              });
            } catch (err) {
              console.error(`Error fetching related images for forest ${forest.name}:`, err);
            }
          } else {
            console.log(`No related images found for forest ${forest.name}`);
          }
        });
      } catch (err) {
        console.error('Error fetching forests:', err);
        setError(`Failed to fetch forests: ${err.message}`);
        setLoading(false);
      }
    };

    fetchForests();
  }, []);

  const filterItems = [
    { label: 'All Forests', href: '#', onClick: () => setFilter('all') },
    { label: 'Tropical', href: '#', onClick: () => setFilter('tropical') },
    { label: 'Temperate', href: '#', onClick: () => setFilter('temperate') },
    { label: 'Boreal', href: '#', onClick: () => setFilter('boreal') },
  ];

  const filteredForests = filter === 'all' ? forests : forests.filter(forest => forest.type === filter);

  if (loading) return <div className="text-center mt-8 text-forest-600">Loading forests...</div>;
  if (error) return <div className="text-center mt-8 text-bark-600">Error: {error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-nature font-bold mb-8 text-center text-forest-800">Forest Gallery</h1>
      <div className="mb-6">
        <Menu title="Filter Forests" items={filterItems} className="w-48" />
      </div>
      {filteredForests.length === 0 ? (
        <div className="text-center mt-8 text-forest-600">No forests found</div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredForests.map((forest) => (
            <ForestCard key={forest.id} forest={forest}>
              <Button
                variant="primary"
                size="small"
                onClick={() => console.log(`Learn more about ${forest.name}`)}
              >
                Learn More
              </Button>
            </ForestCard>
          ))}
        </div>
      )}
    </div>
  );
};

export default ForestGallery;