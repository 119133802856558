import React from 'react';
import DueDiligenceBanner from '../components/DueDiligenceComponents/DueDiligenceBanner';
import AccessAndSize from '../components/DueDiligenceComponents/DueDiligenceSections/AccessAndSize';
import LandClassification from '../components/DueDiligenceComponents/DueDiligenceSections/LandClassification';
import BareLandValue from '../components/DueDiligenceComponents/DueDiligenceSections/BareLandValue';
import ForestAndStumpageValue from '../components/DueDiligenceComponents/DueDiligenceSections/ForestAndStumpageValue';
import RoadNetwork from '../components/DueDiligenceComponents/DueDiligenceSections/RoadNetwork';
import AdditionalDevelopmentValue from '../components/DueDiligenceComponents/DueDiligenceSections/AdditionalDevelopmentValue';
import ContiguityValueAdd from '../components/DueDiligenceComponents/DueDiligenceSections/ContiguityValueAdd';
import PriceAssessment from '../components/DueDiligenceComponents/DueDiligenceSections/PriceAssessment';
import NextSteps from '../components/DueDiligenceComponents/DueDiligenceSections/NextSteps';
import ContactInfo from '../components/ContactInfo';
import About from '../components/About';
import FoldableMapSection from '../components/MapComponents/FoldableMapSection';
import { retrieveNotionPage, parseDueDiligenceData } from '../services/notionService';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const DueDiligenceView = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const fetchDueDiligenceData = async () => {
      try {
        setLoading(true);
        console.log(`Fetching due diligence data for id: ${id}`);
        const pageData = await retrieveNotionPage(id);
        const parsedData = parseDueDiligenceData([pageData])[0];
        setData(parsedData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching due diligence data:', err);
        setError(`Failed to fetch due diligence data: ${err.message}`);
        setLoading(false);
      }
    };

    fetchDueDiligenceData();
  }, [id]);
  if (loading) {
    return <div className="text-center mt-8">Loading due diligence data...</div>;
  }
  if (error) {
    return <div className="text-center mt-8 text-red-600">Error: {error}</div>;
  }
  if (!data) {
    return <div className="text-center mt-8">Due diligence not found</div>;
  }

  return (
    <div className="w-full">
      <DueDiligenceBanner data={data} />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <AccessAndSize data={data} />
        <LandClassification data={data} />
        <BareLandValue data={data} />
        <ForestAndStumpageValue data={data} />
        <RoadNetwork data={data} />
        <AdditionalDevelopmentValue data={data} />
        <ContiguityValueAdd data={data} />
        <PriceAssessment data={data} />
        <FoldableMapSection webmapId={data.webmap_id} extent={data.coordinates} />
        <NextSteps data={data} />
      </div>

      <ContactInfo />
      <About />
    </div>
  );
};

export default DueDiligenceView;