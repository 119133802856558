import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FoldableSection = ({ title, children }) => {
  const [isFolded, setIsFolded] = useState(false);

  const toggleFold = () => {
    setIsFolded(!isFolded);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
      <div className="flex justify-between items-center mb-4 cursor-pointer" onClick={toggleFold}>
        <h3 className="text-xl font-semibold">{title}</h3>
        {isFolded ? <FaChevronDown className="text-gray-500" /> : <FaChevronUp className="text-gray-500" />}
      </div>
      
      {!isFolded && children}
    </div>
  );
};

export default FoldableSection;