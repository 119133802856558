import React, { useState, useEffect, useRef } from 'react';
import Button from './Button';

/**
 * Menu component for forest-themed web app
 * 
 * @param {Object} props - The properties passed to the component
 * @param {string} props.title - The title of the menu
 * @param {Array} props.items - An array of menu items
 * @param {string} props.className - Additional CSS classes to apply to the menu
 * @returns {React.Element} A styled menu component
 */
const Menu = ({ title, items, className = '' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`relative ${className}`} ref={menuRef}>
      <Button
        variant="primary"
        onClick={toggleMenu}
        className="w-full flex justify-between items-center" // Added 'flex' class
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        <span>{title}</span>
        <svg
          className={`w-5 h-5 ml-2 transition-transform duration-200 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </Button>
      <div
        className={`absolute z-10 w-full mt-2 bg-forest-50 rounded-md shadow-lg overflow-hidden transition-all duration-200 ${
          isOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <ul className="py-1">
          {items.map((item, index) => (
            <li key={index}>
              <a
                href={item.href}
                onClick={(e) => {
                  if (item.onClick) {
                    e.preventDefault();
                    item.onClick();
                    setIsOpen(false);
                  }
                }}
                className="block px-4 py-2 text-sm text-forest-800 hover:bg-forest-100 transition-colors duration-200"
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Menu;