import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

/**
 * ProtectedRoute component to restrict access to authenticated users
 * @returns {JSX.Element} Protected route or redirect to login
 */
const ProtectedRoute = () => {
  const { isAuthenticated } = useContext(AuthContext);

  // If the user is authenticated, render the child routes
  // Otherwise, redirect to the login page
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;