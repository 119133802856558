import React from 'react';

/**
 * Button component for forest-themed web app
 * 
 * @param {Object} props - The properties passed to the component
 * @param {string} props.variant - The button variant (default, primary, secondary)
 * @param {string} props.size - The button size (small, medium, large)
 * @param {React.ReactNode} props.children - The content of the button
 * @param {function} props.onClick - The function to call when the button is clicked
 * @param {string} props.className - Additional CSS classes to apply to the button
 * @returns {React.Element} A styled button component
 */
const Button = ({ variant = 'default', size = 'medium', children, onClick, className = '' }) => {
  const baseStyle = 'font-sans rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2';
  
  const variants = {
    default: 'bg-forest-100 text-forest-800 hover:bg-forest-200 focus:ring-forest-500',
    primary: 'bg-forest-600 text-white hover:bg-forest-700 focus:ring-forest-500',
    secondary: 'bg-bark-500 text-white hover:bg-bark-600 focus:ring-bark-400',
  };

  const sizes = {
    small: 'px-3 py-1.5 text-sm',
    medium: 'px-4 py-2 text-base',
    large: 'px-6 py-3 text-lg',
  };

  const buttonStyle = `${baseStyle} ${variants[variant]} ${sizes[size]} ${className}`;

  return (
    <button className={buttonStyle} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;