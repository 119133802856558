import React from 'react';
import { FaLightbulb } from 'react-icons/fa';

const CommentBlock = ({ content = '', title = "Ovis Expert Insights" }) => {
  const bulletPoints = content?.includes('\n') 
    ? content.split('\n').filter(point => point.trim() !== '')
    : [content];

  return (
    <div className="mb-4 bg-gray-100 border-l-4 border-yellow-500 p-4 rounded">
      <div className="flex items-center mb-2">
        <FaLightbulb className="text-lg text-yellow-500 mr-2" />
        <h4 className="text-md font-semibold">{title}</h4>
      </div>
      {bulletPoints.length > 1 ? (
        <ul className="list-disc list-inside text-gray-700">
          {bulletPoints.map((item, index) => (
            <li key={index} className="mb-1">{item.trim().replace(/^-\s*/, '')}</li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-700 italic">{content}</p>
      )}
    </div>
  );
};

export default CommentBlock;